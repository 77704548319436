



.news-articles-header {
  position: relative;
  width: 100%;
  height: 400px; 
  background-image: 
  linear-gradient(0deg, rgba(0, 168, 89, 0.32), 
  rgba(0, 168, 89, 0.32)),
linear-gradient(0deg, rgba(0, 0, 0, 0.5),
 rgba(0, 0, 0, 0.5)),
url('../Assets/conference.jpg'); 
  background-size: cover;
  background-position: center;
}

.news-content-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
 
}
.news-content-header h1 {
  font-size: 64px;
  margin-bottom: 10px;
}

.content p {
  font-size: 24px;
  line-height: 31.2px;
  font-weight: 400;
  font-family: 'Karla';
}
.blog-news-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  justify-items: center;
  background-color: none;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  margin: 0 auto; 
  overflow: hidden;
}
.blog-news-card {
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0px 0px 32px 0px;
  margin-top: 0;
  width: 100%;
  height: 80%;
  position: relative;
  background-color: #f0f0f0;
  border-top: 3px solid #01A85A; 
  border-right: 3px solid #01A85A; 
  border-bottom: 3px solid #01A85A; 
  border-left: 1px solid #01A85A;
  animation: flyIn 0.5s ease;
  margin-bottom: 0;
}



.blog-news-card.expanded {
  grid-column: span 3; /* Expand the card to cover 3 columns */
  height: 100%;
  width: 60%; /* Allow the card height to adjust based on content */
 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow to the expanded card */
  border: none; /* Remove borders when card is expanded */
  transition: all 0.1s ease; /* Smooth transition for expanding the card */
}

.blog-news-card.expanded .blog-news-card-content {
  padding: 20px; /* Adjust padding for better spacing */
  margin-top: 20px; /* Adjust margin for better spacing */
}

.blog-news-card.expanded .blog-readmore-btn {
  display: none; /* Hide the "Read More" button in expanded state */
}

.blog-news-card-image {
  width: 100%;
  height: 40%;
  object-fit: cover;
  transition: transform 0.9s ease;
}

.blog-news-card:hover .blog-news-card-image {
  transform: scale(1.1);
}

.blog-news-card.expanded .blog-news-card-image {
  width: 100%; /* Ensure the image fills the entire width of the card */
  height: 300px; /* Set a fixed height for the expanded image */
  object-fit: cover; /* Maintain aspect ratio and cover the entire space */
  border-radius: 14px 14px 0 0; /* Apply rounded corners only to the top */
}
.blog-news-card-content {
  padding: 20px;
}

.news-blog-card-title {
  font-size: 22px;
  margin-bottom: 10px;
  color: #01A85A;
  text-align: normal;
  font-family: 'Karla', sans-serif;
}

.news-blog-card-description {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: 'karla';
  color: #1f1d21;
  overflow: hidden;
  max-height: 80px; /* Adjust as needed */
  transition: max-height 0.3s ease;
}



.blog-news-card-title {

  margin-top: 30px; /* Adjust as needed */
  padding-bottom: 0;
  margin-bottom: 20px; /* Adjust as needed */
  text-align: left;
  margin-left: 100px;
}

.blog-news-card-title h1 {
  margin: 0;
  font-size: 32px;
  color: #01A85A;
  margin: 0;
}


.blog-news-card hr {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);/* Adjust color and thickness as needed */
  margin: 10px 0; /* Adjust margin as needed */
}

.news-world-epilepsy {
  color: #fff;
  display: inline-flex;
  background: #01A85A;
  height: 40px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  font-family: 'karla';
  gap: 8px;
  background-color: none;
  border: none;
  border-radius: 4px;
  font-family: 'Karla', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  text-transform: uppercase;
}

.news-world-epilepsy:hover {
  color: #fff;
  display: inline-flex;
  background: #01A85A;
}

.blog-news-card-button {
  color: #01A85A;
  display: inline-flex;
  height: 30px;
  padding: 0 30px;
  justify-content: center;
  align-items: center;
  font-family: 'karla';
  gap: 8px;
  background-color: none;
  border: none;
  border-radius: 4px;
 

  font-family: 'Karla', sans-serif;
  font-size: 18px;
  font-weight: 400;

  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-news-card-button:hover {
  background-color: #01A85A;
  color: #f0f0f0;
}

@media only screen and (max-width: 768px) {


  .blog-news-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 24px;
    justify-items: center;
    padding:  10px;
    background-color: none;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 80px;
  }

  .blog-news-card {
    border-radius: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0px 0px 32px 0px;
    width: 90%;
    position: relative; /* Added for child element positioning */
  }
  
  
}