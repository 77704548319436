/* src/components/LoginPage.css */

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: linear-gradient(0deg, rgba(0, 168, 89, 0.32), rgba(0, 168, 89, 0.32)),
                      linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../Assets/admin.jpg'); 
    background-size: cover;
    background-position: center;
  }
  
  .login-container {
    text-align: center;
    background-color: #f9f9f9; 
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  .logo {
    width: 35%; 
    border-radius: 100%; 
    overflow: hidden; 
  }
  
  .logo img {
    width: 100%;
    object-fit: cover;
  }
  form {
    margin-bottom: 30px;
   
  }
  
  .user-input {
    position: relative;
  }
  
  .login-input {
    width: calc(100% - 40px); /* Adjust width to accommodate icon */
    padding: 14px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #01A85A;
  }
  
  .user-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
   
  }
  
  .User-Button {
    width: 100%;
    padding: 14px;
    background-color: #01A85A;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  @media screen and (max-width: 768px){

    .login-container {
      text-align: center;
      background-color: #f9f9f9; 
      padding: 10%;
      border-radius: 8px;
     margin-left: 20px;
     margin-right: 20px;
     width: 100%;
    
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  }
    
 
  