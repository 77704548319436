/* ConsultationComponent.css */

.consultation-container {
    display: flex;
    padding-bottom: 80px;
   
  
}
  
.left-section {
    flex: 1;
    padding-left: 100px;
    margin-right: 20px;
    margin-top: 4%;
  
}


  
.right-section {
    margin-top: 4%;
    flex: 1;
    display: grid;
    margin-right: 40px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px; /* Adjust the value for the desired space between cards */
}
  
.Consultaion {
    font-size: 24px;
    font-weight: medium;
    margin-bottom: 10px;
    font-family: 'karla';
}
  
.sub-title {
    font-size: 32px;
    font-weight: medium;
    margin-bottom: 50px;
    position: relative; 

    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sub-title::after {
    content: ''; 
    position: absolute; 
    left: 10%; 
    bottom: -10px; 
    transform: translateX(-50%); 
    width: 20%; 
    height: 3px; 
    background: var(--Primary-600, #01A85A); 
    border-radius: 9px; 
}
  
.ConsultationParams {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
    font-family: 'karla';
}
  
.cards {
    border: 1px solid #2288A9;
    position: relative;
    width: 90%;
    display: flex; /* Add this line */
    justify-content: center; /* Add this line */
    align-items: center; /* Add this line */
    height: 0;
    padding-bottom: 65%;
    perspective: 400px;
    cursor: pointer;
    border-radius: 9px;
    margin-top: 10px;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #01a85a; /* Circle color */
    margin-right: 10px;
  }
  
  .icon {
    font-size: 24px; /* Adjust the size of the icon */
    color: white;
    width: 24px; /* Icon size */
    height: 24px; /* Icon color */
  }


  .CardContent {
    position: absolute;
    top: 50%; /* Change from 60% to 50% */
    left: 50%; /* Change from 40% to 50% */
    font-size: 16px;
    margin-bottom: 2px;
    margin-right: 5px; /* Add margin-right for spacing */
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'karla';
    font-weight: medium;
}

.CardContent p {
    font-size: 14px;
    font-weight: normal; /* Change from 'regular' to normal */
    font-family: 'karla';
}


.ConsultationButton{

    color: #fff;
  display: flex;
  height: 46px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 4px;
  background: #01A85A;
  font-family: 'Karla';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 30px;
}
.ConsultationButton:hover{
    background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%),
    linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
   color: #1F1D21;
}



@media screen and (max-width: 768px) {
    .consultation-container {
        flex-direction: column; /* Stack sections vertically on smaller screens */
    }

    .left-section {
        padding-left: 10px; /* Adjust padding for smaller screens */
        margin-right: 10px;
    }

    .right-section {
       
        margin-left: 40px;
        margin-right: 10px;

            margin-top: 8%;
            flex: 1;
            display: grid;
           
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px; /* Adjust the value for the desired space between cards */
       
        
    }

   

    .sub-title {
        font-size: 32px;
        font-weight: medium;
        margin-bottom: 50px;
        margin-left: 20px;
        position: relative; 
    
        background: #01A85A;
        background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .sub-title::after {
        content: ''; 
        position: absolute; 
        left: 10%; 
        bottom: -10px; 
        transform: translateX(-50%); 
        width: 20%; 
        height: 3px; 
        background: var(--Primary-600, #01A85A); 
        border-radius: 9px; 
    }

    .ConsultationButton{

       margin-left: 20px;
    }
    .ConsultationButton:hover{
        background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%),
        linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
       color: #1F1D21;
    }
      

    .CardContent {
        font-size: 18px; /* Adjust font size for smaller screens */
    }

    .ConsultationParams {
        font-size: 16px; /* Adjust font size for smaller screens */
    }

    .cards {
        width: 90%; /* Adjust card width for smaller screens */
    }

    .circle {
        width: 40px; /* Adjust circle width for smaller screens */
        height: 40px; /* Adjust circle height for smaller screens */
    }

    .icon {
        font-size: 20px; /* Adjust icon size for smaller screens */
        width: 30px; /* Adjust icon width for smaller screens */
        height: 30px; /* Adjust icon height for smaller screens */
    }
}