.Contact-Us {
    position: relative;
    width: 100%;
    height: 400px; 
    background-image: linear-gradient(0deg, rgba(0, 168, 89, 0.32), rgba(0, 168, 89, 0.32)),
                      linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../Assets/admin.jpg'); 
    background-size: cover;
    background-position: center;
  }
  
  .contact-us-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }

  .contact-us-form-container{
    display: flex;
    justify-content: space-between ;
    margin-left: 40px;

  
    
  }

  .contact-social-icons{
    width: 50%;
    margin-left: 60px;
    padding: 20px;

   
  }

  

  .start-conversation{
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 50px;
    line-height: 32px;
    font-family: 'Karla';
    position: relative; 

    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  
.official-email{
    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: capitalize;
}
.container-map-contact {
    height: 400px; /* Set the height of the map container */
    width: 100%; /* Set the width of the map container */
  }

  .contact-mapping{
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 60px;
    padding-top: 20px;
  }

  .contact-us-map{
    padding-left: 60px;
  }
  .form-contacting{
background-color: white;
margin-top: 10px;
margin-bottom: 10px;
box-shadow: 0 0 4px rgba(219, 223, 221, 0.7);

  }
  


@media (max-width: 768px) {
  .Contact-Us {
    height: 300px; 
  }

  .content {
    top: 100%; 
  }
  .ConsultationContactUs{
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .contact-us-form-container{
    display: flex;
   flex-direction: column;

  
    
  }
  .contact-social-icons{
    width: 80%;
    margin-left: 10px;
    padding: 20px;

   
  }

  .content h1 {
    font-size: 42px;
    padding-left: 20px;
    padding-right: 20px; 
  }

  .content p {
    font-size: 12px;
  
    line-height: 24px; 
  }

  .staff-sub-title {
    font-size: 20px; 
    line-height: 48px; 
  }

  .staff-sub-title::after {
    left: 20%; 
    bottom: -5px; 
    width: 10%; 
  }

  .right-staff,
  .left-staff {
    padding-left: 20px; 
    margin-right: 5px; 
    margin-top: 2%; 
    margin-bottom: 4%; 
  }
}


@media (max-width: 576px) {
  .content {
    top: 30%; 
  }

  .content h1 {
    font-size: 32px; 
  }

  .content p {
    font-size: 16px; 
    line-height: 22px; 
  }

  .staff-sub-title {
    font-size: 24px; 
    line-height: 36px; 
  }

  .staff-sub-title::after {
    width: 40%; 
  }
}




