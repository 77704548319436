.Header-details-blog{
    display: flex;
  
   
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
}
.Details-blog-subtitle{

    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
}
.Details-image-blog{
   width: 80%;
    gap: 0px;
    border-radius: 16px 16px 16px 16px;
    
    
    
}
.this-is-the-title{
font-weight: 700;
line-height: 27px;
width: 80%;
font-size: 22px;
  color: #01A85A;
  text-align: normal;
  font-family: 'Karla', sans-serif;


}
.Details-blogs-des{
    width: 80%;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: 'karla';
  color: #1f1d21;
  overflow: hidden;
 text-align: justify;
 

}


.emergency-form-content{
   
    margin-right: 30px;
    
}


.Blog-details-Related-post{
   
   width: 200%;
    gap: 0px;
    margin-top: 10px;
   
    margin-right: 100px;
   
 
   


    
}
.top-title-details{
    color: #328CB1;
    font-family: Karla;
    
    font-size: 30px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
   
    
}
.Blogs-Others-name{
    font-family: 'Karla', sans-serif;
    font-size: 16px;
    font-weight: 100;
    line-height: 27px;
    text-align: normal;
    

}


.blog-related-posts {
    display: flex;
    flex-direction: column;
  }
  
  .related-post {
    display: flex;
    align-items: center;
   
  }
  
  .related-post img {
    width: 15%; /* Adjust image width as needed */
    margin-right: 20px; /* Adjust spacing between image and text */
  }

  .other-post-images{
    cursor: pointer;
  }

  .other-post-title{
    font-family: 'Karla', sans-serif;
    font-size: 16px;
    font-weight: 100;
    line-height: 27px;
    text-align: normal;
  }
  


  /* Style for container div */
.selected-container-blog-details {
 
  margin-bottom: 20px;
  display: column;
 justify-content: space-evenly;

}

/* Style for select elements */
.select-details-blog {
  width: 150px;
  padding: 10px;
 border: none;
  border-radius: 5px;
 
  outline: none;
margin: 10px;

font-size: 18px;
font-weight: 500;
line-height: 36px;
text-align: left;
color: #008648;


}

/* Style for option elements */
.option-blogs-details {
  background-color: #fff;
  color: #333;
}
.blog-details-archives{
  font-family: Karla;
font-size: 32px;
font-weight: 700;
line-height: 48px;
text-align: left;
color: #328CB1;

}



.newsletter-details {
  text-align: center;
  margin-top: 20px; 
  margin-right: 100%;
  /* Adjust as needed */
}

.newsletter-input-container-details {
  display: inline-flex;
  border: 1px solid #01A85A; /* Border color */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden;
}

.newsletter-input {
  padding: 10px;
  width: 140px; /* Adjust width as needed */
  border: none;
}

.newsletter-btn-details {
  background-color: #01A85A; 
  color: #fff; 
  padding: 10px 10px; 
  font-size: 16px; 
  border: none;
  border-radius: 0 5px 5px 0; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.newsletter-btn-details:hover {
  background-color: #5886b8; /* Darker shade of blue on hover */
}
.news-letter-btn-details{
  color: #fff;
  display: flex;
  height: 46px;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px;
  justify-content: center;
  align-items: center;
 border: 1px solid #01A85A;
  border-radius: 4px;
  background: none;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 30px;
  cursor: pointer;
}

.news-letter-details-blog{
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: #328CB1;

}
.news-letter-details-subscribe{
  font-size: 18px;
}

.date-category{
display: flex;
justify-content: space-around;
margin-right: 50%;
width: Hug (183px)px;
height: Hug (32px)px;
top: 1550px;
left: 26px;
gap: 16px;


}
.category-title-details{
  margin-right: 45%;
  border: none;
  border-radius: 8px;
 padding: none;
 font-weight: 700;
}
.healthCare-details{
  color: #9C2532;

font-size: 16px;
font-weight: 700;
line-height: 30px;
text-align: left;


}



/* BlogCard.css */

.blog-card-details {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  justify-items: center;
  background-color: none;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  margin: 0 auto; 
}

.blog-card-details:hover {
  transform: translateY(-5px);
}

.blog-image-details-like {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content-details-blog {
  padding: 20px;
}

.blog-title-details-blog {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.blog-date-details-blog {
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.blog-excerpt-details {
  font-size: 1rem;
}
.like-others{
 margin-top: 50px;
 margin-left: 30px;
 margin-right: 30px;

}


@media (max-width: 768px) {
  .Header-details-blog{
    display: flex;
  flex-direction: column;
}

.blog-related-posts {
  display: flex;
  flex-direction: column;
}

.related-post {
  display: flex;
  align-items: center;
 
}

.related-post img {
  width: 20%; /* Adjust image width as needed */
  margin-right: 20px; 
  margin: 8px;
  /* Adjust spacing between image and text */
}


.other-post-images{
  cursor: pointer;
}

.other-post-title{
  font-family: 'Karla', sans-serif;
  font-size: 16px;
  font-weight: 100;
  line-height: 27px;
  text-align: normal;

}
.Blog-details-Related-post{
   
  width: 90%;
  text-align: normal;
}
.Details-blog-subtitle{


}
.Details-image-blog{
  width: 95%;
   gap: 0px;
   border-radius: 16px 16px 16px 16px;
   
   
   
}
.this-is-the-title{
font-weight: 700;
line-height: 27px;
width: 95%;
font-size: 22px;
 color: #01A85A;
 text-align: normal;
 font-family: 'Karla', sans-serif;


}
.Details-blogs-des{
   width: 95%;
 font-size: 18px;
 line-height: 27px;
 font-weight: 400;
 font-family: 'karla';
 color: #1f1d21;
 overflow: hidden;
text-align: justify;
align-items: center;




}


.date-category{
  display: flex;
  justify-content: space-around;
  margin-right: 20%;

  width: Hug (183px)px;
height: Hug (32px)px;
top: 1550px;
left: 26px;
gap: 16px;



}
.category-title-details{
  margin-right: 30%;
  border: none;
  border-radius: 8px;
  
 padding: none;
 font-weight: 700;
}
.healthCare-details{
  color: #9C2532;

font-size: 16px;
font-weight: 700;
line-height: 30px;
text-align: left;


}



}