/* src/FAQ.css */
.faq-container {
   
   margin: 30px;
    padding: 10px;
    display: flex;
   justify-content: space-between;
  }
  
  .frequently-asked-questions{
    
    background: rgba(50, 140, 177, 1);
    width: 20%;
    height: 20%;
padding: 50px 96px 154px 52px;
gap: 1px;

margin-right: 20px;


  }
  .faq-item {
   
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
  }
  
  .faq-answer {
    margin-top: 10px;
    padding-left: 20px;
  }
  

  /* Media queries for mobile responsiveness */
@media (max-width: 768px) {
    .faq-container {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: center;
    }

    .frequently-asked-questions {
        width: 100%; /* Full width on smaller screens */
        margin-right: 0;
        padding: 20px; /* Reduce padding for smaller screens */
    }

    .faq-item {
        width: 100%; /* Full width for FAQ items on smaller screens */
    }
}

@media (max-width: 480px) {
    .frequently-asked-questions {
        padding: 15px; /* Further reduce padding for very small screens */
    }

    .faq-question {
        flex-direction: column; /* Stack question text and icon vertically */
    }

    .faq-answer {
        padding-left: 10px; /* Adjust padding for smaller screens */
    }
}