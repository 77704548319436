





.blog-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  justify-items: center;
  background-color: none;

  padding-left: 100px;
  padding-bottom: 100px;
  padding-right: 100px;
  margin: 0 auto; 
  overflow: hidden;
  
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-in {
  animation: slideInFromLeft 0.5s ease forwards;
}

.slide-out {
  animation: slideOutToLeft 0.5s ease forwards;
}


.blog-card {
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0px 0px 32px 0px;
  margin-top: 0;
  width: 100%;
  height: auto;
  position: relative;
  background-color: #f0f0f0;
  border-top: 3px solid #01A85A; 
    border-right: 3px solid #01A85A; 
    border-bottom: 3px solid #01A85A; 
    border-left: 1px solid #01A85A;
  
    animation: flyIn 0.5s ease;
}

.blog-card-image {
  width: 100%;
  height: 40%;
  object-fit: cover;
  transition: transform 0.9s ease;
}

.blog-card:hover .blog-card-image {
  transform: scale(1.1);
}

.blog-card-content {
  padding: 20px;
}

.blog-card-title {
  font-size: 22px;
  margin-bottom: 10px;
  color: #01A85A;
  text-align: normal;
  font-family: 'Karla', sans-serif;
}

.blog-card-description {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: 'karla';
  color: #1f1d21;
  overflow: hidden;
  max-height: 160px; /* Adjust as needed */
  transition: max-height 0.3s ease;
}



.blog-title {

  margin-top: 30px; /* Adjust as needed */
  padding-bottom: 0;
  margin-bottom: 20px; /* Adjust as needed */
  text-align: left;
  margin-left: 100px;
}

.blog-title h1 {
  margin: 0;
  font-size: 32px;
  color: #01A85A;
  margin: 0;
}


.blog-card hr {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);/* Adjust color and thickness as needed */
  margin: 10px 0; /* Adjust margin as needed */
}

.world-epilepsy {
  color: #fff;
  display: inline-flex;
  background: #01A85A;
  height: 40px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  font-family: 'karla';
  gap: 8px;
  background-color: none;
  border: none;
  border-radius: 4px;
  font-family: 'Karla', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  text-transform: uppercase;
}

.world-epilepsy:hover {
  color: #fff;
  display: inline-flex;
  background: #01A85A;
}

.blog-card-button {
  color: #01A85A;
  display: inline-flex;
  height: 30px;
  padding: 0 30px;
  justify-content: center;
  align-items: center;
  font-family: 'karla';
  gap: 8px;
  background-color: none;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;

  font-family: 'Karla', sans-serif;
  font-size: 18px;
  font-weight: 400;

  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-card-button:hover {
  background-color: #01A85A;
  color: #f0f0f0;
}

@media only screen and (max-width: 768px) {


  .blog-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 24px;
    justify-items: center;
    padding:  10px;
    background-color: none;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 80px;
  }

  .blog-card {
    border-radius: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0px 0px 32px 0px;
    width: 90%;
    position: relative; /* Added for child element positioning */
  }
  
  
}