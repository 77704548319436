.about-component {
    position: relative;
    width: 100%;
    height: 400px; 
    background-image: linear-gradient(0deg, rgba(0, 168, 89, 0.32), rgba(0, 168, 89, 0.32)),
                      linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../Assets/admin.jpg'); 
    background-size: cover;
    background-position: center;
  }
  
  .admin-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .admin-content h1 {
    font-size: 50px;
    margin-bottom: 10px;
  }
  
  .content p {
    font-size: 24px;
    line-height: 31.2px;
    font-weight: 400;
    font-family: 'Karla';
  }
 

  .left-staff{
    flex: 1;
    padding-left: 60px;
    margin-right: 30px;
    margin-left: 10px;
    margin-top: 4%;
  
}
.admin-section{
  background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%), 
    linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%), 
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 20px;
}
.admin-contents-body{
 padding: 10px;
  text-justify: inter-word;
  text-align: justify;
}



  .right-staff-about-admin {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10%; 
    margin-bottom: 6%; 
    margin-right: 60px;
    position: relative;
   opacity: 3.6s;
  }
  
  .staff-image-about-admin {
    border-radius: 100%;
    width: 90%;
    
    border-radius: 9px;
    
  }


  .staff-sub-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 50px;
    line-height: 72px;
    font-family: 'Karla';
    position: relative; 

    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.staff-sub-title::after {
    content: ''; 
    position: absolute; 
    left: 10%; 
    bottom: -10px; 
    transform: translateX(-50%); 
    width: 20%; 
    height: 3px; 
    background: var(--Primary-600, #01A85A); 
    border-radius: 9px; 
}

.consulting-admin-header{
  font-size: 24px;
  text-align: center;
}

.admin-department-units {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%), 
    linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%), 
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 20px;
  width: 100%;
    height: 120vh; 
}

.left-side {
  flex: 1;
  background-color: azure;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 30px;
 
}

.department-scope{
align-items: center;
margin-top: 30%;
}



  .units-first-title{
    font-size: 16px;
    color: #01A85A;

    
  }
  .units-second-title{
font-size: 30px;
text-align: normal;
line-height: 50px;

  }
 



.right-side {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  margin-top: 20px;
}

.text-block {
  padding: 10px;
  margin-right: 20px;
}

.text-block h3 {
  margin-top: 0;
  font-size: 20px;
  background-color: #01A85A;
  border-radius: 100%;
  display: inline-block; /* Ensures the circle only takes up the necessary space */
  padding: 8px; 
  color: white;
}


.text-block h4 {
  margin-bottom: 5px;
}
.admin-btn{
  color: #fff;
  display: flex;
  height: 46px;
  padding: 8px 66px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 4px;
  background: #01A85A;
  font-family: 'Karla';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  margin-top: 30px;
}




@media (max-width: 768px) {
  .about-component {
    height: 300px; 
  }

  .content {
    top: 40%; 
  }
  .ConsultationParams{
    font-size: 14px;
    padding-right: 20px;
  }

  .content h1 {
    font-size: 42px;
    padding-left: 20px;
    padding-right: 20px; 
  }

  .content p {
    font-size: 12px;
  
    line-height: 24px; 
  }

  .staff-sub-title {
    font-size: 20px; 
    line-height: 48px; 
  }

  .staff-sub-title::after {
    left: 20%; 
    bottom: -5px; 
    width: 10%; 
  }

  .right-staff,
  .left-staff {
    padding-left: 20px; 
    margin-right: 5px; 
    margin-top: 2%; 
    margin-bottom: 4%; 
  }
  .admin-department-units {
    flex-direction: column;
    height: auto;
  }
  
.department-scope{
  align-items: center;
  margin-top: 10%;
  }
  
  .left-side {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .right-side {
    flex: 2;
    margin-left: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  .right-staff-about-admin {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 8%; 
    margin-bottom: 6%; 
    margin-left: 60px;
    position: relative;
   opacity: 3.6s;
  }
  
  .staff-image-about-admin {
    border-radius: 100%;
    width: 120%;
    height: 200%;
    border-radius: 9px;
    margin-top: 15%;
    box-shadow: 0 0 20px rgba(81, 88, 86, 0.7);
  }

}


@media (max-width: 576px) {
  .content {
    top: 30%; 
  }

  .content h1 {
    font-size: 32px; 
  }

  .content p {
    font-size: 16px; 
    line-height: 22px; 
  }

  .staff-sub-title {
    font-size: 24px; 
    line-height: 36px; 
  }

  .staff-sub-title::after {
    width: 40%; 
  }
}




.Executives {
    background-color: #f7f2f2;
    padding: 50px;
   
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Top-executive h1 {
    margin-bottom: 30px; 
    position: relative; 

    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Top-executive h1::after {
    content: ''; 
    position: absolute; 
    left: 60%; 
    bottom: -10px; 
    transform: translateX(-60%); 
    width: 50%; 
    height: 3px; 
    background: var(--Primary-600, #01A85A); 
    border-radius: 9px; 
}
.team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-left: 10px;
    margin-right: 10px;
   
  }
  
  .team-member-card {
    position: relative;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    border-top: 1px solid #01A85A; 
    border-right: 3px solid #01A85A; 
    border-bottom: 3px solid #01A85A; 
    border-left: 1px solid #01A85A;
    transition: all 0.3s ease;
    padding: 4px; 
  }

  .team-member-card:hover {
    transform: scale(0.9); 
}
  
  .team-member-image {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    border-radius: 10px;
    transition: transform 0.3s ease;
   
}

.team-member-image:hover {
    transform: scale(1.1); 
}
  .member-details {
    position: absolute;
    bottom: 2px;
    left: 2px;
    right: 2px;
    background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%),
    linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    padding: 20px;
    border-radius: 0 0 10px 10px;
  }

  .member-details:hover {
    background-color: white; 
}
  
  .member-name {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 30px;
    font-family: 'Karla';
  }
  
  .member-position {
    margin: 0;
    font-size: 16px;
    color: #555;
    text-align: center;
  }
 

  .organogram {
    background: rgb(249, 241, 223);
   
  }
  
  .organogram-image {
    max-width: 100%; 
    height: auto; 
    border-radius: 50px;
    display: flex; 
    margin: 0 auto;
   
  }
  

