/* DoctorProfile.css */
.doctor-container {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    background-color: azure;
    border-radius: 20px; /* Adjust the value to control the curvature */
    overflow: hidden; /* Ensures content inside follows curved shape */
  }
    .doctor-info h1 {
      margin-bottom: 0; 
    }
    .doctor-info p {
      margin-top: 1.4; 
    }
    .left-content-admin {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 8%; 
      margin-bottom: 6%; 
  }
  
  .right-content-admin {
      flex: 1;
      margin-top: 6%; 
      margin-bottom: 2%; 
      
  }
    .doctor-image-admin-head {
      max-width: 85%;
      border-radius: 9px;
      box-shadow: 0 0 20px rgba(88, 91, 90, 0.7);
      
    }
    
    .doctor-info {
      margin-top: 30px;
      margin-right: 60px;
      background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%),
      linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);
      border-radius: 50px 0 50px 0;
      box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1F1D21;
      padding: 2px;
      transition: transform 0.3s ease;
  }
  
 
  .doctor-info:hover {
    transform: scale(1.05);
  }
    .DoctorTitle{
      font-size: 32px;
      font-weight: medium;
    }
    .bullet-list-admin li {
      margin-bottom: 15px;
      text-align: justify;
      margin-right: 40px; 
      display: flex;
      line-height: 1.5;
      font-size: '18px';
      font-family: 'karla';
      align-items: center; 
    }
    .circle-about {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #01a85a; 
      margin-right: 10px;
    }
    .icon {
      color: #fff; 
      font-size: 20px; 
    }
    .title {
      flex: 1;
      font-size: '18px';
    }
    .Director {
      background: #01A85A;
      background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .bold-text {
      font-weight: bold;
    }
  
  
  
  
    @media only screen and (max-width: 768px) {
      .doctor-container {
          flex-direction: column;
          align-items: center;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0;
      }
      .bold-text{
        font-size: 16px;
      }
      .left-content-admin{
        margin-left: 10px;
        margin-right: 10px;
      }
      .title{
        font-size: 14px;
      }
      .right-content-admin{
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 50px;
      }
      .doctor-image-admin-head{
        width: 95%; 
        margin-top: 20px;
    }
      .doctor-info {
          margin-right: 0;
          margin-left: 0;
      }
      .medical-director {
          margin-left: 0;
      }
      .bullet-list-admin li {
          margin-right: 0;
      }
      .doctor-image-admin {
          max-width: 50%;
          border-radius: 6px;
      }
      .DoctorTitle {
          font-size: 16px;
      }
      .bullet-list-admin li {
          font-size: 16px;
      }
  }