/* Add this CSS to your styles */

.navbar {
    display: flex;
    padding: 2px 10px;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 8px 32px 0px rgba(30, 30, 30, 0.16);
    margin-top: 10px;
    margin-bottom: 10px;
  }
.navbar .fixed{
  background: #01A85A;
  transition: .3s ease-in-out;
}
.navbar .fixed .navbar-links li{
  color: #1F1D21;
}

  .navbar .navbar-collapse {
    display: flex;
    align-items: center; 
    padding: 4px 1px;
    
  }
  
  .navbar .navbar-links {
    display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
  gap: 22px;
  }
  
  .navbar .NavbarHome {
    display: flex;
  align-items: center;
  
  }


  .Image {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    height: auto;
    margin-right: 8px;
    
    /* Add a border-radius for a circular shape */
    /* Add a subtle shadow */
    transition: transform 0.3s ease;
  }
  
  .Image:hover {
    transform: scale(2.1); /* Increase the scale on hover */
    /* Increase the shadow on hover */
  }

  .mobile-menu-toggle {
    display: none;
    margin: 0;
    padding: 0;
  }

   
.about-us {
    display: flex;
    align-items: center;
  }
  .accounts-btn{
    color: #fff;
    display: flex;
  
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
   
    border: none;
    border-radius: 4px;
   background-color: white;
    border: 1px solid #01A85A;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
 
    cursor: pointer; 
  }
  .accounts-btn:hover{
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
   
    border: none;
    border-radius: 4px;
   background-color: white;
    border: 1px solid #01A85A;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
 
    cursor: pointer; 
  }


  .navbar .navbar-links li {
    margin-right: 4px;
    transition-property: background-color;
    transition-property: color;
    position: relative; /* Added for positioning the stroke */
  }
  


  
  .navbar .navbar-links  li::after {
    content: '';
    position: absolute;
    left: 0;
   
    bottom: -8px;
    width: 80%;
    height: 3px; /* Adjust the height of the stroke as needed */
    background: transparent; /* Initial color of the stroke */
    border-radius: 9px;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
    
  }
  
  .navbar .navbar-links li:hover::after,
  .navbar .navbar-links li.active::after {
    background: var(--Primary-600, #01A85A);
    transition-duration: .4s;
    transition-timing-function: ease-in-out; 
    /* Color of the stroke on hover or active */
  }
  
  .navbar .navbar-links a {
    text-decoration: none;
    color: var(--Dark-800, #1F1D21);
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 24px;
     transition-duration: .4s;
    transition-timing-function: ease-in-out; 
  }
  
  .navbar .navbar-links .nav-item::after {
    content: '';
    position: absolute;
    top: 200%;
    right: 10px; /* Adjust the distance from the item */
    transform: translateY(-50%);
    border: 5px solid transparent;
    border-top-color: #555; /* Change the color as needed */
  }
  
  .navbar .navbar-links .nav-item .dropdown-menu {
    position: absolute;
    top: calc(100% + 100px); /* Position the dropdown menu below the list item with some spacing */
    display: none;
    min-width: 10rem; /* Set a minimum width for the dropdown menu */
    padding: 1.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #555; /* Text color for dropdown items */
    text-align: left;
    list-style: none;
    background-color: #fff; /* White background for the card-like appearance */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    border-radius: 9px;
    transform: translateX(-1rem);
    transition-property: opacity,visibility, transform;
  }
  
  
  .navbar .navbar-links .nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
     transition-duration: .4s;
    transition-timing-function: ease-in-out; 
  }
  
  .navbar .navbar-links .nav-item .dropdown-item {
    display: block;
    padding: 10px 20px;
    clear: both;
    font-weight: bold;
    color: #555; /* Text color for dropdown items */
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: none;
  }
  
  .navbar .navbar-links .nav-item .dropdown-item:hover {
    background-color: #01A85A;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 6px;

     /* Change the background color on hover as needed */
    color: #fff; /* Change the text color on hover as needed */
  }

  .navbar .navbar-links a{
    transition-property: color ;
  }
  
  .navbar .navbar-links a:hover {
    color: #01A85A;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
    
  }
  
  .navbar .get-started-button {
    color: #fff;
    display: flex;
    height: 46px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: #01A85A;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
  }
  

  .dropdown-icon {
    margin-top: 4px;
    margin-left: 4px; /* Adjust the margin as needed to move the icon down */
  }
 


/* Media query for mobile view */
@media only screen and (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 120px;
    right: 20px; 
    color: #fff; 
    background-color: #01A85A; 
    border: none;
    border-radius: 50%; 
    padding: 12px; 
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);
  }

  
  .navbar .navbar-collapse {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 175px;
    left: -100%; /* Updated to align with the top navigation */
    width: 90%; /* Adjusted width for better responsiveness */
    background: #01A85A;
    padding: 20px; /* Reduced padding for better mobile view */
    margin-top: 0; /* Removed excessive margin */
    box-shadow: 0px 8px 32px 0px rgba(30, 30, 30, 0.16);
    z-index: 2;
    transition: left 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.5s ease;
    animation: bounceUp 0.3s ease forwards;
  }
     


  .navbar .navbar-collapse.open {
    display: flex; 
    left: 0;
  }
  .navbar .navbar-links {
     flex-direction: column;
    align-items: flex-start; 
    padding: 0;
   margin-top: 0;
    gap: 2em; 
  }




  .navbar .navbar-links li {
    margin-right: 0;
  
    position: relative; 
  }
  .navbar .navbar-links a {
    font-size: 18px;
   
    
  }
  

  .navbar .navbar-links a:hover {
    color: #ffff;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
    
  }


  @keyframes bounceUp {
    0% {
      transform: translateY(0);
    }
    70% {
      transform: translateY(-10px); /* Bounce up */
    }
    100% {
      transform: translateY(0);
    }
  }
}





/* Media query for mobile view */
@media only screen and (max-width: 320px) {
  .mobile-menu-toggle {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 105px;
    right: 30px; 
    color: #fff; 
    background-color: #01A85A; 
    border: none;
    border-radius: 50%; 
    padding: 12px; 
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);
  }

  
  .navbar .navbar-collapse {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 10%;
    left: -100%; 
    width: 38%;
    background: #01A85A;
    padding: 150px;
    margin-top: 80px;
    box-shadow: 0px 8px 32px 0px rgba(30, 30, 30, 0.16);
    z-index: 10;
    transition: left 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.5s ease; /* Slower transition for left position */
    animation: bounceUp 0.3s ease forwards; /* Animation for bounce effect */
  }
     


  .navbar .navbar-collapse.open {
    display: flex; 
    left: 0;
  }
  .navbar .navbar-links {
     flex-direction: column;
    align-items: flex-start; 
    padding: 0;
   margin-top: 0;
    gap: 2em; 
  }




  .navbar .navbar-links li {
    margin-right: 0;
  
    position: relative; 
  }
  .navbar .navbar-links a {
    font-size: 18px;
   
    
  }
  

  .navbar .navbar-links a:hover {
    color: #ffff;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
    
  }


  @keyframes bounceUp {
    0% {
      transform: translateY(0);
    }
    70% {
      transform: translateY(-10px); /* Bounce up */
    }
    100% {
      transform: translateY(0);
    }
  }
}


