.emergency-call{
    width: 100%;
    
  padding-top: 20px;
    

left: -2px;

    height: 700px; 
    background-image: linear-gradient(0deg, rgba(220, 231, 226, 0.32), rgba(0, 168, 89, 0.32)),
                      linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../Assets/emergency-call.jpg'); 
    background-size: cover;
    background-position: center;
}

.calls {
    color: white;
    font-family: 'Karla';
    display: flex;
   

   
  }
  .title-emegencies{
    color: #FF0000;
    font-family: Karla;
font-size: 28px;
font-weight: 700;
line-height: 72px;
text-align: left;
margin-right: 20px;


  }



  .call-this-number-for-help {
    width: 80%; /* Set the width and height of the container */
    height: 80%;
    border-radius: 60%; /* Make the container circular */
    /* Add a white border */
    overflow: hidden; /* Clip the content to the container's shape */
    display: flex; /* Use flexbox to center the image */
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-right: 60px;
    margin-left: 60px;
  }

  .call-this-number-for-help img {
    width: 100%; /* Make the image fill the container */
    height: auto; /* Maintain aspect ratio */
  }

  .emergency-calls-medicare{
    margin-top: 140px;
    margin-left: 60px;
  }
  .emergency-calls-medicare p{
   margin-top: 20px;
   font-size: 18px;
   font-family: Karla;
   font-weight: 200;
   line-height: 24px;
  }
    
  .medical-cares-hours{
    font-size: 60px;
    color: #ffff;
  font-family: Karla;
  }

  .call-icons-emergency{
    color: #fff;
   
    height: 46px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: none;
    border: 2px solid #01A85A;
    font-family: 'Karla';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    
  }


  @keyframes dance {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
 


   

    
 

  
  
 
 


  @media screen and (max-width: 768px){
    .emergency-call{
      width: 100%;
      padding-top: 20px;
        height: 150%; 
        background-image: linear-gradient(0deg, rgba(220, 231, 226, 0.32), rgba(0, 168, 89, 0.32)),
                          linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url('../../Assets/emergency-call.jpg'); 
        background-size: cover;
        background-position: center;
}

.calls {
 display: flex;

 flex-direction: column-reverse;
} 

.emergency-calls-medicare{
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.call-this-number-for-help {
  width: 80%; 
  height: 80%;
  border-radius: 60%; 
  overflow: hidden; 
  display: flex; 
  justify-content: center;
  align-items: center;
 
}
.title-emegencies{
  display: flex;
  flex-direction: column;
 
}

.call-icons-emergency{
 margin-right: 20px;

}

.emergency-calls-medicare p{
  margin-top: 20px;
  font-size: 18px;
 font-family: 'Karla';
  font-weight: 200;
  line-height: 24px;
 }
 
 .call-icons-emergency{
  color: #fff;
 
  height: 46px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 20%;
 width: 100%;
 color: #01A85A;
  font-family: 'Karla';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  
}

 
  }