.marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: #151313;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  background: linear-gradient(90deg, rgba(0, 168, 83, 0.32) -89.5%, rgba(251, 218, 90, 0.28) 46.1%, rgba(34, 136, 169, 0.32) 186.5%);
  border: 1px solid #1F1D21;
  font-size: 20px; 
  font-family: 'karla';
  font-weight: 500;
 
  
 /* Add this line with your preferred border color */
}


.marquee > div {
  display: inline-block;
  animation: marqueeAnimation linear infinite;
  
}

@keyframes marqueeAnimation {
  to {
    transform: translateX(-100%);
  }
}
