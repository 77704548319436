

.news-letter-header{
  padding-left: 10%;
}

.newsLetter {
  text-align: center;
  margin-top: 10px;
  padding-bottom: 50px;
 
   /* Adjust as needed */
}
.news-letter-headings{
 
}
.header-news-title{
  font-size: 40px;
}
.new-letter-pages-first{
  display: flex;
  padding: 60px;
  
  background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%), 
  linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%), 
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border-radius: 20px;
}
.logo-news-letter-page{
  width:100%;
  border-radius: 40%;
  transition: transform 0.5s ease;
}
.logo-news-letter-page:hover{
  transform: scale(0.9); /* Scale down on hover */
  border-radius: 50%;
  background: #f8f4f4;
  

}

.newsLetter-input-container {
  display: inline-flex;
  border: 1px solid #01A85A; /* Border color */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden;

}

.newsLetter-input {
 
  padding: 18px;
  width: 40em; /* Adjust width as needed */
  border: none;
}

.newsLetter-btn {
  background-color: #01A85A; 
  color: #fff; 
  padding: 10px 10px; 
  font-size: 16px; 
  border: none;
  border-radius: 0 5px 5px 0; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.newsletter-btn:hover {
  background-color: #01A85A; /* Darker shade of blue on hover */
}

.news-letter-header{
 text-align: center;
}

@media screen and (max-width: 768px){
  .newsLetter-input {
      padding: 18px;
      width: 10em; /* Adjust width as needed */
      border: none;
    }
    .header-news-title{
      font-size: 2px;
    }
    .new-letter-pages-first{
      display: flex;
      flex-direction: column;
    }

    .news-letter-headings{
 font-size: 20px;
 text-align: center;
    }
    .newsLetter-input-container {
      display: inline-flex;
      border: 1px solid #01A85A; /* Border color */
      border-radius: 5px; /* Rounded corners */
      overflow: hidden;
 
      
    }
}
