.Career {
    text-align: center;
    margin-top: 100px;
  }
  
  .Career-heading {
    font-size: 3em;
    color: #333;
  }
  
  .career-sub-heading {
    font-size: 1.2em;
    color: #666;
  }
  