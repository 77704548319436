.component-management {
    position: relative;
    width: 100%;
    height: 400px; /* You can adjust the height according to your preference */
    background-image: linear-gradient(0deg, rgba(0, 168, 89, 0.32), rgba(0, 168, 89, 0.32)),
                      linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                      url('../Assets/conference.jpg'); /* Adjust the path to your image */
    background-size: cover;
    background-position: center;
  }
  
  .content-management {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .content-management h1 {
    font-size: 64px;
    margin-bottom: 10px;
  }
  
  .content-management p {
    font-size: 24px;
    line-height: 31.2px;
    font-weight: 400;
    font-family: 'Karla';
  }
  .right-staff-management{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 15%; 
    margin-bottom: 6%; 
    margin-right: 10px;
    box-shadow: #01A85A;
  }

  .left-staff-management{
    flex: 1;
    padding-left: 30px;
    margin-right: 30px;
    margin-top: 4%;
  
}

  .staff-image-management{
    max-width: 95%;
    border-radius: 9px;
    
    box-shadow: 0 0 20px rgba(88, 91, 90, 0.7);
  }



  .staff-sub-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 50px;
    line-height: 72px;
    font-family: 'Karla';
    position: relative; 

    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.staff-sub-title::after {
    content: ''; 
    position: absolute; 
    left: 10%; 
    bottom: -10px; 
    transform: translateX(-50%); 
    width: 20%; 
    height: 3px; 
    background: var(--Primary-600, #01A85A); 
    border-radius: 9px; 
}


.To-management-kano {
    background-color: #f2f7f4;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Top-executive h1 {
    margin-bottom: 30px; 
    position: relative; 

    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Top-executive h1::after {
    content: ''; 
    position: absolute; 
    left: 60%; 
    bottom: -10px; 
    transform: translateX(-60%); 
    width: 50%; 
    height: 3px; 
    background: var(--Primary-600, #01A85A); 
    border-radius: 9px; 
}
.team-grid-management {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden; /* Ensure the overflow is hidden */
    white-space: nowrap; 
   
  }
  
  .team-member-card {
    position: relative;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    border-top: 1px solid #01A85A; /* Top border with a thickness of 3px */
    border-right: 3px solid #01A85A; /* Right border with a thickness of 2px */
    border-bottom: 3px solid #01A85A; /* Bottom border with a thickness of 1px */
    border-left: 1px solid #01A85A;
    transition: all 0.3s ease;
    padding: 4px; /* Adjusted padding */
    
  }

  .team-member-card:hover {
    transform: scale(0.9); /* Scale down the card on hover */
}
.footer-staff-management{
  margin-top: none;
}


@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide-in {
  animation: slideIn 2s ease;  
}

.slide-out {
  animation: slideOut 2s ease; 
}
  
  .team-member-image {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    border-radius: 10px;
    transition: transform 0.3s ease;
   
}

.team-member-image:hover {
    transform: scale(1.1); 
}
  .member-details {
    position: absolute;
    bottom: 2px;
    left: 2px;
    right: 2px;
    background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%),
    linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    padding: 20px;
    border-radius: 0 0 10px 10px;
  }

  .member-details:hover {
    background-color: white; 
}
  
  .member-name {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 30px;
    font-family: 'Karla';
  }
  
  .member-position {
    margin: 0;
    font-size: 16px;
    color: #555;
    text-align: center;
  }
 

  @media screen and (max-width: 768px) {


    .component-management {
      position: relative;
      width: 100%;
      height: 300px; 
      border-radius: 18px;
     
    }
    
    .content-management {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;
      width: 90%;
    }
    
    .content-management h1 {
      font-size: 34px;
      margin-top: 60px;
     
    }
    
    .content-management p {
      font-size: 20px;
      line-height: 27px;
      font-weight: 400;
      font-family: 'Karla';
      text-align: center;
    }

    .left-staff-management{
     
      padding-left: 10px;
      margin-right: 10px;
      margin-top: 4%;
      width: 95%;
    
  }
  
    .staff-image-management{
      max-width: 80%;
      border-radius: 9px;
      
      box-shadow: 0 0 20px rgba(88, 91, 90, 0.7);
    }
  
    .team-grid-management {
      display: flex;
      overflow-x: auto; 
      scroll-snap-type: x mandatory; 
      -webkit-overflow-scrolling: touch; 
    }
  
    .team-member-card {
      flex: 0 0 calc(100% - 20px); 
      margin-right: 10px; 
      scroll-snap-align: start; 
      animation: slideInMobile 2s ease; 
    }

    .member-name {
      margin: 0;
      font-size: 16px;
      font-weight: 200;
      text-align: center;
      line-height: 10px;
      font-family: 'Karla';
      margin-right: 20px;
    }
    
    .member-position {
      margin: 0;
      font-size: 16px;
      color: #555;
      text-align: center;
    }

    
.Top-executive h1 {
  margin-bottom: 30px; 
  position: relative; 

  background: #01A85A;
  background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Top-executive h1::after {
 display: none;
}
.footer-staff-management{
  margin-top: 40%;
}
  }
  
  @keyframes slideInMobile {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }


  
 

