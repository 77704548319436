
.mySwiper{
 width: 100%;
 height: 120%;
}
.Home-wiper {
  position: relative;
  width: 100%;
  height: 115vh; /* 100% of the viewport height */
}
/* Add animation classes for the left and right content */
.left-content {
  width: 50%;
  height: 100%;
  float: left;
  
}

.text-container{
  margin-top: 35%;
  margin-left: 100px;
  text-align: justify;
  margin-right: 20px;
  color: #fff;
  
}
.text-container h1{
 text-align: center;
  color: #fff;
  font-size: 40px;
  
}
.text-container p{
  text-align: center;
   color: #fff;
   font-size: 34px;
  
   
 }



.right-content {
  width: 50%;
  float: right;
}

/* Apply styles to left and right content separately */
/* Circle Style */
.circle-home {
  
    position: absolute;
    top: 50%;
    left: 58%;
    transform: translate(-50%, -50%);
    width: 110%; /* adjust as needed */
    height: 85%; /* adjust as needed */
    border: 20px solid #25ce7f; /* border color and width */
    border-radius: 50%; /* to make it circular */
    z-index: 0;
    animation: scanning 2s infinite alternate; /* Animation properties */
  }
  
  @keyframes scanning {
    0% {
      top: 50%;
      left: 60%;
    }
    
    
  }
/* Image Style */
.left-content img {
  display: block;
  width: 100%;
  align-items: center;
  margin-top: 15%;
  margin-left: 20px;
  object-fit: cover;
  border-radius: 20px;
  position: relative; /* make sure the container is positioned */
  z-index: 1;
}

.right-content .text-container {
  text-align: center;
  color: #1F1D21;
  font-family: 'karla';
}

/* Apply animation to left and right content */
.animate__slideInLeft {
  animation: slideInLeft 1s forwards;
}

.animate__slideInRight {
  animation: slideInRight 1s forwards;
}


.slide1 {
  background-color: #1a5e7d; /* Background color for slide 1 */
}

.slide2 {
  background-color: #01A85A; /* Background color for slide 2 */
}

.slide3 {
  background-color: #01A85A; /* Background color for slide 3 */
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px); /* Start slightly left of its final position */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Slide to its final position */
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(50px); /* Start slightly right of its final position */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Slide to its final position */
  }
}



/* Add other CSS styles as needed */


  .swiper-button-prev{
 display: none;
  
  }

 

  .swiper-button-next{
  
    
   display: none;
    
    }


  .federal-neuropyschiatric{
    color: #25ce7f;
    display: flex;
    height: 26px;
   
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: #25ce7f;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    margin-left: 40px;
   
  }
  .federal-neuropyschiatric:hover{
    color: #25ce7f;
    display: flex;
    height: 26px;
   
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: #25ce7f;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
   
  }
  
   .btn {
    color: #01A85A;
    display: flex;
    height: 46px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: white;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 30px;
   margin-left: 13%;
    cursor: pointer; /* Adjust font size as needed */
  }
  .btn{
    transition-property: background-color;
    transition-timing-function: ease-in-out;
    transition-duration: .4s;
  }
  .btn:hover{
   background-color: white;
    border: 1px solid #151618;
    border-color: #01A85A;
    color: #01A85A;
  }
  


  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-50px); /* Start slightly above its final position */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Slide to its final position */
    }
  }



/* Home.css */
.Modal {
  position: absolute;
  top: 130%;
  left: 95%;
  transform: translate(-50%, -50%);
  background-color: rgb(249, 244, 244);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  
 
}

.Overlay {

  position: absolute;
  top: 40%;
  left: 40%;
 
  width: 40%;
  height: 50%;
  z-index: 1;
}

.book-close-btn{
  margin-left: 95%;
  background-color: #01A85A;
  color: white;
 cursor: pointer;
  border-radius: 30px;
}


 /* Media query for mobile devices */
@media only screen and (max-width: 768px) {
 
  
  .swiper-slide {
    text-align: center; 
  }

  
  
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  

  .mySwiper{
    width: 100%;
    height: 100%;
   }
   .Home-wiper {
   
     height: 60vh; 
   }
  
  
   
   .text-container {
    position: relative;
    padding-left: 100%;
    left: 150%;
    transform: translate(-80%, -80%);
    z-index: 100; 
    width: 180%; 
   padding: 200% ;
   text-align: center;
     
  }
   .text-container h1{
    
    justify-content: center;
     color: #fff;
     font-size: 18px;
     margin-top: 160%;
 
     
   }


   .text-container p{
     text-align: center;
      color: #fff;
      font-size: 18px;
     
      
    }
    .circle-home {
  
     display: none;
    }

    .federal-neuropyschiatric {
      display: none; /* Hide on mobile devices */
  }
   
   
   
   /* Apply styles to left and right content separately */
   .left-content img {
     display: block;
     width: 180%;
     align-items: center;
     margin-top: 15%;
     margin-left: 10px;
     object-fit: cover;
     border-radius: 20px;
   }
   
  

  .btn {
    color: #fff;
    display: flex;
    
    height: 36px;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: #01A85A;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-top: 20px;
    cursor: pointer; /* Adjust font size as needed */
  }
  .btn{
    transition-property: background-color;
    transition-timing-function: ease-in-out;
    transition-duration: .4s;
  }
  .btn:hover{
   background-color: white;
    border: 1px solid #151618;
    border-color: #01A85A;
    color: #01A85A;
  }
  
}