/* BlogEditor.css */
.blog-editor {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.blog-editor input[type='text'],
.blog-editor input[type='date'],
.blog-editor textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.blog-editor textarea {
    height: 200px;
}

.blog-editor input[type='file'] {
    margin-bottom: 20px;
}

.blog-editor textarea:focus,
.blog-editor input[type='text']:focus,
.blog-editor input[type='date']:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px #007bff;
}

.blog-text-editor{
    color: #fff;
    display: flex;
    height: 46px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: #01A85A;
    font-family: 'Karla';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 30px;
}

.blog-text-editor:hover{
    background: #01A85A;
    cursor: pointer;
   
}