.App {
  
  margin: 0;
  padding: 0;
   /* Add this line to prevent any potential overflow causing unwanted scrolling */
}

html,
body {
 
  margin: 0;
  padding: 0;
  font-family: "Karla", sans-serif;
}

/* scrollbar.css */

/* Track */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #8f3699; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Radius of the scrollbar thumb */
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3); /* Add a shadow to the scrollbar thumb */
  transform: skew(20deg) /* Radius of the scrollbar thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #0dbc6b; /* Color of the scrollbar thumb on hover */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.Live-News-app-file{
 margin-top: 10%;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {

  .Live-News-app-file{
    margin-top: 0;
   }
   
}