.services-component {
  position: relative;
    width: 100%;
    height: 400px; 
    background-image: linear-gradient(0deg, rgba(0, 168, 89, 0.32), rgba(0, 168, 89, 0.32)),
                      linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../Assets/admin.jpg'); 
    background-size: cover;
    background-position: center;
}

.services-content {
  position: absolute;
  top: 60%;
  left: 60%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  
}

.services-content h1 {
  font-size: 50px;
  margin-bottom: 10px;
}


.hospital-service-grids{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
 
  background-color: #01A85A;

}

.Hospital-Services-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px; 
  margin-top: 6px;
}

.checkmark {
  width: 30px;
  height: 30px;
  background-color: #28a745; /* Green color for the checkmark */
  border-radius: 50%; /* To make it a circle */
  display: inline-block;
  margin-right: 10px; /* Add some spacing between the checkmark and text */
}

.services {
  flex: 1;
  margin: 0 20px; /* Adjusted margins */
  padding: 10px; /* Added padding for better spacing */
  background-color: #fff; /* Added background color */
  border-radius: 10px; /* Added border radius for rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added box shadow for depth */
  transition: all 0.3s ease; /* Smooth transition effect */
}

.services:hover {
  transform: translateY(-10px); /* Added slight lift effect on hover */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Enhanced box shadow on hover */
}

.directorate-sub-title {
  font-size: 24px; /* Adjusted font size */
  font-weight: bold; /* Changed font weight to bold */
  margin-bottom: 20px; /* Adjusted margin */
  padding-bottom: 10px; /* Added padding for separation */
  border-bottom: 2px solid #01A85A; /* Added bottom border for decoration */
  color: #1F1D21; /* Changed text color */
}

.ConsultationParams {
  font-size: 16px; /* Adjusted font size */
  line-height: 1.5; /* Improved line spacing for readability */
  color: #555; /* Changed text color */
}

@media (max-width: 768px) {

  .hospital-service-grids{
   display: flex;
   flex-direction: column;
  
  }
  .services {
    margin: 0 4px; /* Adjusted margins for smaller screens */
  }

  .staff-sub-title {
    font-size: 20px; /* Adjusted font size for smaller screens */
  }
}
