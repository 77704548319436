.statistics {
    background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%), 
    linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%), 
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    padding: 50px 80px;
  }
  .title-stat {
    text-align: center;
    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .StatCards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    
  }
  .StatCards p{
    font-family: 'Karla';
    font-size: 38px;
    font-weight: 700;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center
  }
  .StatCards span{
    font-family: 'Karla';
    font-size: 18px;
    font-weight: 200;
    line-height: 12px;
    color: #FFFFFF;
    text-align: center
  }
  .statCard {
    width: 150px;
    top: 165px;
    left: 250px;
    height: 150px;
    padding: 35px, 6px, 34px, 6px;
    border-radius: 12px;
    background: linear-gradient(0deg, #343137, #343137),
    linear-gradient(283.26deg, rgba(251, 218, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%);
    text-align: center;
  }
  .Top-Cards h1 {
    margin-bottom: 30px; 
    position: relative; 
    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Top-Cards h1::after {
    content: ''; 
    position: absolute; 
    left: 50%; 
    bottom: -10px; 
    transform: translateX(-60%); 
    width: 10%; 
    height: 3px; 
    background: var(--Primary-600, #01A85A); 
    border-radius: 9px; 
}
@media screen and (max-width: 768px) {

  .statistics {
    background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%), 
    linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%), 
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    padding: 50px 30px;
  }
    .StatCards {
      display: grid;
      grid-template-columns: repeat(2, 1fr); 
      gap: 10px; 
    }

    .statCard {
      width: 150px;
      top: 165px;
     
      height: 150px;
      padding: 35px, 6px, 34px, 6px;
      border-radius: 12px;
      background: linear-gradient(0deg, #343137, #343137),
      linear-gradient(283.26deg, rgba(251, 218, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%);
      text-align: center;
    }
    
  
  }
  