
.ServiceTop {
   
    background-color: azure;
}
.OurServices{
     font-size: 32px;
     font-weight: medium;
     position: relative;
     margin-left: 100px;
     margin-top: 10px;
     margin-bottom: 40px;
     background: #01A85A;
     background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 100%);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
}

.OurServices::after{
    content: '';
    position: absolute;
    left: 5%;
    bottom: -10px;
    transform: translateX(-50%);
    width: 10%;
    height: 3px;
    background: var(--Primary-600, #01a85a);
    border-radius: 9px;
}


.Services-container {
    display: flex;
    padding: 2px;
    margin-top: 8%;
    padding-right: 20px;
    padding-left: 90px;
    padding-bottom: 100px;
}

.HeaderService {
    font-weight: medium;
    font-family: 'karla';
    margin-left: 100px;
    font-size: 24px;
    margin-bottom: 10px;
    
}
.ServicesHeaders {
   
    position: relative; 
   
    top: 50px; 
}




.Services-section {
    flex: 1;
    display: grid;
    margin-right: 50px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 20px;
    grid-gap: 20px;
}

.ServiceCards {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    padding: 20px;
    cursor: pointer;
    border-radius: 9px;
    margin-top: 10px;
    border: none;
}

.ServiceCards:hover {
    background-color: #01a85a;
    color: white;
}

.icons {
    font-size: 24px;
    color: #328cb1;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.ouricons {
    font-size: 24px;
    color: #b17632;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.ServiceCards:hover .ouricons {
    color: white;
}

.Babicons {
    font-size: 24px;
    color: rgb(88, 88, 13);
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.ServiceCards:hover .Babicons {
    color: white;
}

.Emergencyicons {
    font-size: 24px;
    color: #01a85a;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.ServiceCards:hover .Emergencyicons {
    color: white;
}

.Genicons {
    font-size: 24px;
    color: #01a85a;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.ServiceCards:hover .Genicons {
    color: white;
}

.ServiceCards:hover .icons {
    color: white;
}

.ServicesContent {
    text-align: center;
    font-family: 'karla';
    font-weight: medium;
}

.ServicesContent p {
    font-size: 16px;
    font-weight: normal;
    font-family: 'karla';
    margin: 0;
    /* Reset margin */
}


@media screen and (max-width: 768px){
    .ServiceTop {
       
        
    }

    .OurServices {
        font-size: 24px;
        margin-left: 30px;
        margin-right: 30px; 
        margin-bottom: 20px;
    }

    .OurServices::after {
        width: 5%;
        bottom: -5px;
    }

    .Services-container {
        display: flex;
        padding: 20px;
        margin-top: 8%;
        padding-right: 10px;
        padding-left: 40px;
       
    }

    .HeaderService {
        margin-left: 30px;
        margin-right: 30px; 
        font-size: 20px; 
    }

    .ServicesHeaders {
        top: 30px; 
    }

    .Services-section {
        flex: 1;
    display: grid;
   
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
    grid-gap: 20px;
    }

    .ServiceCards {
        width: 100%;
        height: auto;
        padding: 4px;
        margin-bottom: 8px;
    }

    .icons,
    .ouricons,
    .Babicons,
    .Emergencyicons,
    .Genicons {
        font-size: 20px; 
        width: 40px; 
        height: 40px; 
    }

    .ServicesContent {
        padding: 0 10px; 
    }

    .ServicesContent p {
        font-size: 14px; 
    }
}