
  .HomeCards{
    padding: 10px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(251,252,246,1) 35%, rgba(255,255,255,1) 100%);
  }
  
  /* Add this CSS to your Navigation.css file */
  .HomeCards h1 .underline {
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    width: 10%;
    height: 3px; /* Adjust the height of the underline */
    background: var(--Primary-600, #01A85A); /* Color of the underline */
    border-radius: 9px;
  }
  .HomeCards h1{
    background: #01A85A;
  background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  .Hero-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; /* Adjust the gap value as needed */
    justify-content: center; /* Center the grid horizontally */
    margin: 100px auto; /* Center the grid horizontally and vertically */
    width: 90%; /* Adjust the width as needed */
  }
  
  .hero-card {
    perspective: 400px;
    cursor: pointer;
    position: relative;
    width: calc(140% - 140px); /* Adjusted width with margins */
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 400px;
    cursor: pointer;
    border-radius: 9px;
    margin: 0 auto; 
    height: 250px; 
    overflow: hidden; 
   
   
  }

  
.vison-core-mission{
  text-align: center;
  margin-left: '29%';
  margin-right: '29%';
 
}
.navigating-with-purpose{
  text-align: 'center';
  margin-top: '4%';
  position: relative;
  
}


  
  .Hero-card-content {
    position: absolute;
    width: 80%; /* Adjust the width as needed */
    height: 80%; /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #2288A9;
    border-radius: 8px;
    backface-visibility: hidden;
    transition: transform 1s; /* Increase the transition duration */
  }
  
  .front {
    background-color: #FFFFFF;
  }
  
  .back {
    transform: rotateY(180deg) scale(1); /* Add scale property */
    background-color: #01A85A;
    opacity: 0;
    pointer-events: none; /* Prevent hovering on the back content */
    transition: opacity 1s, transform 1s; /* Increase the transition duration */
    color: #1F1D21;
  }
  
  .hero-card:hover .front {
    transform: rotateY(180deg);
    height: 100%; /* Ensure the front side fully covers the back side */
  }
  
  .hero-card:hover .back {
    transform: rotateY(0deg) scale(1); /* Add scale property */
    opacity: 1;
  }
  
  /* Add this to your Navigation.css */
  .Hero-card-content.front {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #01a85a; /* Circle color */
    position: absolute; /* Position absolutely */
    top: -20px; /* Adjust the top position to be above the card */
    left: 50%; /* Position in the center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    z-index: 1; /* Ensure it's on top of the cards */
}

.circle.back {
  background-color: white; /* Set background to white when card flips */
}
  
  /* Adjust the CSS for the icon */
  .circle svg {
    color: white; /* Icon color */
    width: 24px; /* Icon size */
    height: 24px;
    position: absolute; /* Add this line */
    top: 50%; /* Add this line */
    left: 50%; /* Add this line */
    transform: translate(-50%, -50%); /* Add this line */
  }
  
  
  /* Adjust the CSS for the icon */
.circle.eye {
  border: 4px solid #01a85a;

  background-color: #ffff; /* Circle color for eye icon */
}

.circle.FaUnity {
  border: 4px solid #9a8e0c;
  background-color: #ffff; /* Circle color for FaUnity icon */
}

.circle.LuGoal {
  border: 4px solid #01a85a;
  background-color: #ffff; /* Circle color for LuGoal icon */
}



@media only screen and (max-width: 768px) {
  .Hero-grid-container {
   display: flex;
   flex-direction: column;
  }

  .hero-card {
    width: calc(100% - 40px); /* Adjusted width for smaller screens with increased margin */
    height: 200px; /* Decrease height for smaller screens */
  }

  .Hero-card-content {
    width: 90%; /* Adjusted width for smaller screens */
    height: 90%; /* Adjusted height for smaller screens */
    padding: 10px; /* Decrease padding for smaller screens */
  }

  .circle {
    width: 30px; /* Decrease circle size for smaller screens */
    height: 30px; /* Decrease circle size for smaller screens */
    top: -15px; /* Adjust the top position for smaller screens */
  }

  /* Adjust the CSS for the icon size */
  .circle svg {
    width: 18px; /* Decrease icon size for smaller screens */
    height: 18px; /* Decrease icon size for smaller screens */
  }

  .vison-core-mission{
    text-align: center;
    margin-left: 40px;
    margin-right: 40px;
    text-align: justify;
  text-justify: inter-word;
  font-family: 'Karla';
   
  }
  .HomeCards h1{
   
  }

  .HomeCards{
   padding: 4%;
  }
}