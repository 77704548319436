.directorate-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .left-directorate {
    flex: 1;
    padding: 20px;
    margin-top: 4%;
    text-align: justify;
  }
  
  .left-directorate h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .left-directorate .directorate-header {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .left-directorate .directorate-header p {
    margin-bottom: 20px;
  }
  
  /* Apply styles for the background */
  .directorate-container {
    background-image: linear-gradient(rgba(0, 168, 89, 0.32), rgba(0, 168, 89, 0.32)), 
                      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                      url('../Assets/admin.jpg');
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Apply styles for the text content */
.left-directorate {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .left-directorate h2 {
    color: #333;
  }
  
  .left-directorate .directorate-header {
    color: #666;
    line-height: 1.6;
  }
  
  .left-directorate .directorate-header p {
    margin-bottom: 10px;
  }
  
  .left-directorate .directorate-header ul {
    padding-left: 20px;
  }
  
  .left-directorate .ConsultationParams li {
    margin-bottom: 5px;
  }
  


@media (max-width: 768px) {
  .about-component {
    height: 300px; 
  }

  .content {
    top: 40%; 
  }
  .ConsultationParams{
   
  }

  .content h1 {
    font-size: 42px;
    padding-left: 20px;
    padding-right: 20px; 
  }

  .content p {
    font-size: 12px;
  
    line-height: 24px; 
  }

  .directorate-header-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 50px;
    line-height: 72px;
    font-family: 'Karla';
    position: relative; 

    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.directorate-header-title::after {
    content: ''; 
    position: absolute; 
    left: 10%; 
    bottom: -10px; 
    transform: translateX(-50%); 
    width: 20%; 
    height: 3px; 
    background: var(--Primary-600, #01A85A); 
    border-radius: 9px; 
}

  .right-staff,
  .left-staff {
    padding-left: 20px; 
    margin-right: 5px; 
    margin-top: 2%; 
    margin-bottom: 4%; 
  }
}


@media (max-width: 576px) {
  .content {
    top: 30%; 
  }

  .content h1 {
    font-size: 32px; 
  }

  .content p {
    font-size: 16px; 
    line-height: 22px; 
  }

  .staff-sub-title {
    font-size: 24px; 
    line-height: 36px; 
  }

  .staff-sub-title::after {
    width: 40%; 
  }
}



