.about-component {
  position: relative;
  width: 100%;
  height: 400px; 
  background-image: linear-gradient(0deg, rgba(0, 168, 89, 0.32), rgba(0, 168, 89, 0.32)),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url('../Assets/admin.jpg'); 
  background-size: cover;
  background-position: center;
}

.admin-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.admin-content h1 {
  font-size: 50px;
  margin-bottom: 10px;
}

.content p {
  font-size: 24px;
  line-height: 31.2px;
  font-weight: 400;
  font-family: 'Karla';
}


.left-staff{
  flex: 1;
  padding-left: 30px;
  margin-right: 10px;
 
  margin-top: 4%;

}
.admin-section{
background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%), 
  linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%), 
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
}

.right-staff{
margin-right: 30px;
margin-left: 10px;
margin-top: 11%;

}
.admin-image{
  max-width: 100%;
  border-radius: 9px;
}



.staff-sub-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 50px;
  line-height: 72px;
  font-family: 'Karla';
  position: relative; 

  background: #01A85A;
  background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.staff-sub-title::after {
  content: ''; 
  position: absolute; 
  left: 10%; 
  bottom: -10px; 
  transform: translateX(-50%); 
  width: 20%; 
  height: 3px; 
  background: var(--Primary-600, #01A85A); 
  border-radius: 9px; 
}





/* src/CenteredImageWithCards.css */
.container-clinical-service {
  display: grid;
  grid-template-areas: 
      "top-left image-top top-right"
      "bottom-left image-top bottom-right";
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto auto;
  justify-items: center;
  align-items: center;
  height: 100%;
  padding: 50px;
}

.centered-image {
  width: 100%; 
  margin: 0 auto;
 margin-top: -5%;
  grid-area: image-top;
}

.card-clinical-services {
  background-color: rgba(240, 240, 240, 0.8);
  padding: 10px;
  width: 100%;
  border-radius: 10% 8% 5% 2%;
  text-align: left;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease; /* Adding transition for smooth animation */
}

.card-clinical-services:hover {
  transform: translateY(-5px); /* Lift the card up by 5px on hover */
}

.card-clinical-services li{
list-style-type: none;

}
.clinical-department-header{
  text-align: center;
 margin-top: 50px;
}



.top-left {
grid-area: top-left;
width: 120%;
margin-left: 5%;
padding-bottom: 3%;
}

.bottom-left {
grid-area: bottom-left;
width: 130%;
margin-left: 50%;
margin-top: -35%;

}

.top-right {
grid-area: top-right;
width: 120%;
margin-right: 10%;
}

.bottom-right {
grid-area: bottom-right;
width: 130%;
margin-right: 30%;
margin-top: -25%;
}


.service-circle-clinical{
display: flex;
  align-items: center;
  margin: 10px;
}

.service-clinical-grid{
display: grid;
grid-template-columns: repeat(2, 1fr); 
padding: 0;
margin: 0;

}


@media (max-width: 768px) {
.about-component {
  height: 300px; 
}

.container-clinical-service {
  grid-template-areas: 
    "top-left top-right"
    "image-top image-top"
    "bottom-left bottom-right"
    "image-bottom image-bottom";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  padding: 10px;
}
.service-clinical-grid{
 display: flex;
 flex-direction: column;
 
}

.centered-image {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}


.top-left {
  width: 100%;
  margin-left: 0;
}

.bottom-left {
  width: 100%;
  margin-left: 0;
}

.top-right {
  width: 100%;
}

.bottom-right {
  width: 100%;
  margin-right: 0;
}

.content {
  top: 40%; 
}
.ConsultationParams{
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

.content h1 {
  font-size: 42px;
  padding-left: 20px;
  padding-right: 20px; 
}

.content p {
  font-size: 12px;

  line-height: 24px; 
}

.staff-sub-title {
  font-size: 20px; 
  line-height: 48px; 
}

.staff-sub-title::after {
  left: 20%; 
  bottom: -5px; 
  width: 10%; 
}

.right-staff,
.left-staff {
  padding-left: 20px; 
  margin-right: 5px; 
  margin-top: 2%; 
  margin-bottom: 4%; 
}

.how-it-works-container {
  gap: 1.5rem;
}

.how-it-works-title {
  font-size: 2.5rem;
}

.how-it-works-content {
  flex-direction: row;
}

.steps-container {
  display: block;
}
}

@media (min-width: 1024px) {
.how-it-works-container {
  padding: 4rem 1.5rem;
}

.how-it-works-title {
  font-size: 3rem;
}

}


@media (max-width: 576px) {
.content {
  top: 30%; 
}

.content h1 {
  font-size: 32px; 
}

.content p {
  font-size: 16px; 
  line-height: 22px; 
}

.staff-sub-title {
  font-size: 24px; 
  line-height: 36px; 
}

.staff-sub-title::after {
  width: 40%; 
}
}




.Executives {
  background-color: #f7f2f2;
  padding: 50px;
 
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Top-executive h1 {
  margin-bottom: 30px; 
  position: relative; 

  background: #01A85A;
  background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Top-executive h1::after {
  content: ''; 
  position: absolute; 
  left: 60%; 
  bottom: -10px; 
  transform: translateX(-60%); 
  width: 50%; 
  height: 3px; 
  background: var(--Primary-600, #01A85A); 
  border-radius: 9px; 
}
.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
 
}

.team-member-card {
  position: relative;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  border-top: 1px solid #01A85A; 
  border-right: 3px solid #01A85A; 
  border-bottom: 3px solid #01A85A; 
  border-left: 1px solid #01A85A;
  transition: all 0.3s ease;
  padding: 4px; 
}

.team-member-card:hover {
  transform: scale(0.9); 
}

.team-member-image {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 10px;
  transition: transform 0.3s ease;
 
}

.team-member-image:hover {
  transform: scale(1.1); 
}
.member-details {
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%),
  linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  padding: 20px;
  border-radius: 0 0 10px 10px;
}

.member-details:hover {
  background-color: white; 
}

.member-name {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  font-family: 'Karla';
}

.member-position {
  margin: 0;
  font-size: 16px;
  color: #555;
  text-align: center;
}


.organogram {
  background: rgb(249, 241, 223);
 
}

.organogram-image {
  max-width: 100%; 
  height: auto; 
  border-radius: 50px;
  display: flex; 
  margin: 0 auto;
 
}


