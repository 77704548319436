.about-history-component {
  position: relative;
  width: 100%;
  height: 400px; 
  background: linear-gradient(0deg, rgba(255, 0, 0, 0.32), rgba(255, 0, 0, 0.32)),
  linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url('../Assets/about.jpg'); 
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}

.content-history {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 85%;
  align-items: center;
}

.content-history h1 {
  font-size: 34px;
 
}

.content-history p {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: 'Karla';
  text-align: center;
}
.right-staff-about {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 6%; 
  margin-bottom: 6%; 
  margin-right: 60px;
  position: relative;
}

.staff-image-about {
  border-radius: 100%;
  width: 100%;
  border-radius: 9px;
  margin-top: 15%;
  box-shadow: 0 0 20px rgba(81, 88, 86, 0.7);
}

.doctor-image-about {
  width: 80%;
  height: 50%;
  margin-right: 3px;
  border-radius: 9px;
  margin-top: 40%;
}

.second-image {
  position: absolute;
  top: 20%; /* Adjust this value to position the second image */
  animation: zoomInOut 5s infinite alternate; /* Adjust duration and other animation properties as needed */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1); /* Initial size */
  }
  100% {
    transform: scale(1.2); /* Zoomed out size */
  }
}



.left-staff-history{
  flex: 1;
  padding-left: 30px;
  margin-right: 30px;
  margin-top: 4%;
  

}






.staff-sub-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 50px;
  line-height: 72px;
  font-family: 'Karla';
  position: relative; 

  background: #01A85A;
  background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.staff-sub-title::before {
content: ''; 
position: absolute; 
bottom: -5px; /* Adjusted position to be inside the line */
left: 0; 
width: 10px; /* Circle diameter */
height: 10px; /* Circle diameter */
background: var(--Primary-600, #01A85A); /* Circle color */
border-radius: 50%; /* Make it a circle */
animation: moveCircle 2s ease-in-out infinite; /* Animation for moving the circle */
}

@keyframes pulse {
0% { width: 20%; } /* Start with 20% width */
50% { width: 80%; } /* Expand width to 80% at halfway point */
100% { width: 20%; } /* Return to 20% width */
}

@keyframes moveCircle {
0% { left: 0; } /* Start at the left end of the line */
50% { left: 50%; } /* Move to the right end of the line at halfway point */
100% { left: 0; } /* Return to the left end of the line */
}




@media (max-width: 768px) {


  .about-history-component {
    position: relative;
    width: 100%;
    height: 300px; 
   
  }
  
.staff-image-about {
  border-radius: 100%;
  width: 95%;
  border-radius: 9px;
 
  margin-left: 60px;
  box-shadow: 0 0 20px rgba(81, 88, 86, 0.7);
}

.doctor-image-about {
  width: 70%;
  height: 50%;
  margin-left: 70px;
  border-radius: 9px;
  margin-top: 40%;
}

.content-history {
 align-items: center;
 text-align: center;
 padding: none;
 margin-top: 30px;
}

.ConsultationParams{

  margin-right: 20px;
  font-size: 14px;

}

.content h1 {
  font-size: 22px;
 margin-top: 40px;
 
}
.Consultaion{
  display: none;
}

.content p {
  font-size: 12px;
  line-height: 14px; 
  text-align: center;
  text-justify: inter-word;
}

.staff-sub-title {
  font-size: 20px; 
  line-height: 48px; 
  margin-left: 20px;
  margin-top: 30px;
}

.staff-sub-title::after {
  content: ''; 
  position: absolute; 
  left: 10%; 
  bottom: -10px; 
  
  background: var(--Primary-600, #01A85A); 
  border-radius: 9px; 
}

.right-staff,
.left-staff-history {
  padding-left: 60px; 
  margin-right: 5px; 
  margin-top: 2%; 
  margin-bottom: 4%; 
}

.left-staff-history{

  padding-left: 30px;
  margin-right: 30px;
  margin-top: 4%;
  width: 92%;

}
.organogram-image img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: rgba(255, 255, 255, 0.5);
}

.staff-image{
  max-width: 10%;
  border-radius: 9px;
  margin-left: 20px;
}

}


@media (max-width: 576px) {
.content {
  top: 30%; 
}

.content h1 {
  font-size: 32px; 
}

.content p {
  font-size: 16px; 
  line-height: 22px; 
}

.staff-sub-title {
  font-size: 24px; 
  line-height: 36px; 
}

.staff-sub-title::after {
  width: 40%; 
}
}




.Executives {
  background-color: #f7f2f2;
  padding: 50px;
 
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Top-executive h1 {
  margin-bottom: 30px; 
  position: relative; 

  background: #01A85A;
  background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Top-executive h1::after {
  content: ''; 
  position: absolute; 
  left: 60%; 
  bottom: -10px; 
  transform: translateX(-60%); 
  width: 50%; 
  height: 3px; 
  background: var(--Primary-600, #01A85A); 
  border-radius: 9px; 
}
.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
 
}

.team-member-card {
  position: relative;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  border-top: 1px solid #01A85A; 
  border-right: 3px solid #01A85A; 
  border-bottom: 3px solid #01A85A; 
  border-left: 1px solid #01A85A;
  transition: all 0.3s ease;
  padding: 4px; 
}

.team-member-card:hover {
  transform: scale(0.9); 
}

.team-member-image {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 10px;
  transition: transform 0.3s ease;
 
}

.team-member-image:hover {
  transform: scale(1.1); 
}
.member-details {
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background: linear-gradient(90deg, rgba(0, 168, 89, 0.32) -89.5%, rgba(251, 218, 90, 0.2784) 46.1%, rgba(34, 136, 169, 0.32) 186.5%),
  linear-gradient(283.26deg, rgba(103, 251, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  padding: 20px;
  border-radius: 0 0 10px 10px;
}

.member-details:hover {
  background-color: white; 
}

.member-name {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  font-family: 'Karla';
}

.member-position {
  margin: 0;
  font-size: 16px;
  color: #555;
  text-align: center;
}


.organogram {
  background: rgb(249, 241, 223);
 
}

.organogram-image {
  max-width: 100%; 
  height: auto; 
  border-radius: 50px;
  display: flex; 
  margin: 0 auto;
 
}
