/* Footer Container */
.footer-container {
   
    background: linear-gradient(0deg, #343137, #343137),
linear-gradient(283.26deg, rgba(251, 218, 90, 0.106) -44.23%, rgba(255, 0, 0, 0.084) 22.88%, rgba(34, 136, 169, 0.134) 96.47%);

    color: #fff;
    padding: 50px 80px;
   
    
  }
  
  /* Footer Content */
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  
  /* Footer Brand */
  .footer-brand {
    display: flex;
    align-items: center;
  }
  
  .footer-logo {
    width: 100px;
    height: auto;
    margin-right: 10px;
  }
  
 
  /* Footer Links */
  .footer-links {
    display: flex;
  }
  
  .footer-section {
    margin-right: 20px;
  }
  
  .footer-section-title {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
    font-family: 'karla';
  }
  
  .footer-link-list {
    list-style: none;
    padding: 0;
  }
  
  .footer-link-list li {
    margin-bottom: 10px;
   
  }
  
  .footer-link-list a {
    color: #FFFFFF;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 16px;
    line-height: 12px;
  }
  
  .footer-link-list a:hover {
    color: #ccc;
  }
  .footer-link-list h3 {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  

  /* Footer Divider */
  .footer-divider {
    border: 0;
    border-top: 1px solid #FAFAFA80;
    margin: 20px 0;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: none;
    color: #fff;
  display: flex;
  height: 46px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 4px;
  background: #01A85A;
  font-family: 'Karla';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 30px;
}
  
  .footer-copyright {
    font-size: 14px;
  }
  
  .footer-social-icons {
    display: flex;
   
  }
  
  .footer-social-icons a {
    color: #fff;
    font-size: 20px;
    margin-right: 25px;
    margin-top: 10px;
    text-decoration: none;
  }
  
  .footer-social-icons a:hover {
    color: #ccc;
  }

  .footer-button-scroll {
    display: flex;
    justify-content: space-around; /* Adjust this value to control the spacing */
    margin-top: 20px;
    width: 100%;
    height: 46px;
}
.footer-button-two {
    background: #00A859;
    height: 63px;
    width: 100px;
    font-size: 18px;
    line-height: 21.04px;
    font-weight: 700;
    font-family: 'karla';
    border-radius: 4px 4px 0px 0px; /* Corrected border-radius syntax */
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    position: relative; /* Added positioning */
}

.footer-button-two:hover {
    background:  #FFFFFF;
    height: 63px;
    width: 100px;
    font-size: 18px;
    line-height: 21.04px;
    font-weight: 700;
    font-family: 'karla';
    border-radius: 4px 4px 0px 0px; /* Corrected border-radius syntax */
    border: none;
    color: #00A859;
    cursor: pointer;
    position: relative; /* Added positioning */
}


.newsletter {
  text-align: center;
  margin-top: 20px; /* Adjust as needed */
}

.newsletter-input-container {
  display: inline-flex;
  border: 1px solid #01A85A; /* Border color */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden;
}

.newsletter-input {
  padding: 10px;
  width: 140px; /* Adjust width as needed */
  border: none;
}

.newsletter-btn {
  background-color: #01A85A; 
  color: #fff; 
  padding: 10px 10px; 
  font-size: 16px; 
  border: none;
  border-radius: 0 5px 5px 0; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.newsletter-btn:hover {
  background-color: #5886b8; /* Darker shade of blue on hover */
}


.news-letter-btn{
  color: #fff;
  display: flex;
  height: 46px;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px;
  justify-content: center;
  align-items: center;
  
 border: 1px solid #01A85A;
  border-radius: 4px;
  background: none;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 30px;
  cursor: pointer;
}
.arrow-footers{

  margin-left: 2px;
  margin-right: 2px;
}
.footer-two-icon {
    position: absolute; /* Positioned relative to the .footer-button-two */
    height: 24px;
    width: 24px;
    top: 20px; /* Adjusted top position */
    left: 10px; /* Adjusted left position */
    color: white;
    transform: rotate(-180deg); /* Corrected rotation syntax */
}@media (max-width: 767px) {
  .footer-container {
    padding: 30px 20px; /* Adjust padding for smaller screens */
    text-align: center; /* Center text */
  }

  .footer-content {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center content */
  }

  .footer-section {
    margin: 0; /* Remove margin */
    margin-bottom: 20px; /* Add some bottom margin for spacing */
    width: auto; /* Set width to auto */
    text-align: center; /* Center text */
  }

  .footer-links {
    display: block; /* Set display to block */
    text-align: center; /* Center links */
  }

  .footer-link-list a {
    display: block; /* Set links to display as block */
    margin-bottom: 10px; /* Add some bottom margin for spacing */
  }

  .footer-divider {
    margin: 15px 0; /* Adjust margin */
  }

  .footer-bottom {
    margin-top: 20px; /* Adjust margin */
    width: auto; /* Set width to auto */
    text-align: center; /* Center text */
  }
}