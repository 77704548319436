

.Header-header-social{
    background: '#00A859';
    text-align: "center";
    display: "flex";
    justify-content: "space-between";
    align-items: "center";
    padding: "10px 20px";
}
@media only screen and (max-width: 768px) {
    .Header-header-social{
      
    }
    .danwanua{
       font-size: 8px;
    }
}