

.Consultaion{
    padding-top: 8%;
    
}
.emergency-form{
margin-top: 60px;
margin-left: 60px;
max-width: '50%';

    background: white;
   
}
.emegency-form-header-fed{
    height: '700px',
}

.make-enquiry{
    display: flex;
}
.Form-with-instant-response{
margin-left: 80px;
margin-top: 10%;
}
.with-access-to{
    font-size: 30px;
}
.hours{
    font-size: 40px;
    color: #01A85A;
  font-family: Karla;
}
.instant-emergency{
    font-size: 40px;
}


@media screen and (max-width: 768px) {
    .emergency-form{
        margin-top: 60px;
        margin-left: 20px;
        margin-right: 20px;
        max-width: 200%;
     
       
       
       
           
        }
        .make-enquiry{
            display: flex;
            flex-direction: column;
            padding-right: 100px;
            padding-left: 0;
            align-items: center;
            max-width: 400%;
            
        }
        .Form-with-instant-response{
           
            }
            .emegency-form-header-fed{
                height: '700px',
            }

    .Consultaion {
        padding-top: 2px;
    }

    .form-title-content{
        margin-top: 10%;
    }
    
}