/* DoctorProfile.css */
.doctor-container {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  background-color: azure;
  border-radius: 20px; /* Adjust the value to control the curvature */
  overflow: hidden; /* Ensures content inside follows curved shape */
}
  .doctor-info h1 {
    margin-bottom: 0; 
  }
  .doctor-info p {
    margin-top: 1.4; 
  }
  .left-content-medical {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 3%; 
    margin-bottom: 6%; 
}

.right-content-medical {
    flex: 1;
    margin-top: 4%; 
    margin-bottom: 2%; 
    
}
  .director-image-medical {
    max-width: 80%;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: 0 0 30px rgba(88, 91, 90, 0.7);
   
  }
  .doctor-border {
    margin-top: 30px; 
   
  }
 
  .doctor-info-medical {
    margin-top: 10px;
    margin-left: 60px;
   
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1F1D21;
    padding: 2px;
    transition: transform 0.3s ease;
}

.medical-director {
    text-align: center;
    display: block;
    margin-left: 50px;
}

.doctor-info-medical:hover {
  transform: scale(1.05);
}

.medicat-director-word-title{

  font-size:42px;
  margin-right: 60px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 700;
text-align: left;
font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
}
  .doctor-title{
    font-size: 22px;
    font-weight: medium;
    margin-top: 2px;
  }
  .bullet-list li {
    margin-bottom: 15px;
    text-align: justify;
    margin-right: 100px; 
    display: flex;
    line-height: 1.5;
    font-size: '18px';
    font-family: 'karla';
    align-items: center; 
  }
  .circle-about-medical {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #01a85a; 
    margin-right: 10px;
  }
  .icon-medical {
    color: #fff; 
    font-size: 20px; 
  }
  .title {
    flex: 1;
    font-size: '18px';
  }
  .Director {
    background: #01A85A;
    background: linear-gradient(to left, #01A85A 13%, #1F1D21 50%, #01A85A 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .bold-text-medical {
    font-weight: bold;
    font-size: 22px;
  }




  @media only screen and (max-width: 768px) {
    .doctor-container {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
    .bold-text-medical{
      font-size: 16px;
    }
    .medicat-director-word-title{

      font-size:20px;
      
      margin-bottom: 20px;
      margin-top: 10px;
      font-weight: 700;
    text-align: normal;
    font-family: "Karla", sans-serif;
     
    }
    .DoctorTitle{
      font-size: 14px;
      font-weight: medium;
      text-align: normal;
    }
    .bold-text-medical {
      
      font-size: 14px;
      text-align: left;
    }
    .circle-about-medical {
    display: none;
     
    }
  
    .left-content-medical{
      margin-left: 4px;
      margin-right: 4px;
    }
    .title{
      font-size: 14px;
    }
    .right-content-medical{
      margin-left: 30px;
      margin-right: 30px;
      
    }
  

    .doctor-info-medical {
    
      
      margin-left: 10px;
      margin-right: 60px;
   
    transition: transform 0.3s ease;
  }
    .doctor-info {
        margin-right: 0;
        margin-left: 0;
    }
    .medical-director {
        margin-left: 0;
    }
    .bullet-list li {
        margin-right: 0;
    }
    .director-image-medical {
      width: 70%;
      height: 70%;
      border-radius: 10%;
      object-fit: cover;
    }
    .doctor-border {
      margin-top: 30px; 
    }
    
    .bullet-list li {
        font-size: 16px;
    }
    .doctor-border {
      margin-top: 30px; 
    }
}